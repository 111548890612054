import { types, helpers } from 'utilities';
import { 
  DEFAULT_CONFIG, 
  DEFAULT_API_RESULT
} from './../variables';

export const adminGetProducts = async(_: any): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/admin/products', {
      method: 'GET',
      // body: JSON.stringify(query.body)
    });
    
    if(response.status !== 200 ){
      throw new Error(`GET Products HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET Products Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const adminGetProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/admin/products/${query.body.id}`, {
      method: 'GET',
    });

    if(response.status !== 200 ){
      throw new Error(`GET Admin Product HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET Admin Product Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const getProducts = async(_: any): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/products', {
      method: 'GET',
      // body: JSON.stringify(query.body)
    });
    
    if(response.status !== 200 ){
      throw new Error(`GET Products HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET Products Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const getProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}`, {
      method: 'GET',
    });

    if(response.status !== 200 ){
      throw new Error(`GET Product HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET Product Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const getRegions = async(): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/regions', {
      method: 'GET',
    });

    if(response.status !== 200 ){
      throw new Error(`GET Product HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET Product Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const postChatMessages = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(query.url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(query.body.payload)
    });

    helpers.logToOutput('POST CHAT response json', response);

    if(response.status !== 200 ){
      throw new Error(`POST CHAT HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();

      helpers.logToOutput('POST CHAT response json', responseJson);
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST CHAT Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const createPaymentIntent = async(query: any) : Promise<types.IApiHandledResponse> => {

  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/pay', {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    if(response.status !== 200 ){
      throw new Error(`POST Payment Intent HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST Payment Intent', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const createEnterpriseProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    helpers.logToOutput('Enterprise Create: Sending API Request...')
    helpers.logToOutput('Enterprise Create: Query : ', query)
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/products', {
      method: 'PUT',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('PUT: Enterprise Product: Response : ', response);

    const responseJson = await response.json();

    if(response.status !== 200 ){
      helpers.logToOutput('PUT: RES: RES: RES', response);
      helpers.logToOutput('PUT: RES: RES: RES', responseJson);

      //throw new Error(`PUT: Enterprise Product: HTTP error! Status: ${response.status}`);

      return {
        status: DEFAULT_API_RESULT.FAILURE,
        body: responseJson
      }

    } else {
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
    
  } catch (error: any) {
    helpers.logToOutput('PUT: Enterprise Product: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const deleteProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}`, {
      method: 'DELETE',
      body: JSON.stringify({})
    });

    if(response.status !== 200 ){
      throw new Error(`DELETE: Product: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('DELETE: Product: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const startProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}/start`, {
      method: 'POST',
      body: JSON.stringify({})
    });

    if(response.status !== 200 ){
      throw new Error(`POST: ProductStart: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: ProductStart:', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const hibernateProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}/hibernate`, {
      method: 'POST',
      body: JSON.stringify({})
    });

    if(response.status !== 200 ){
      throw new Error(`POST: ProductHibernate: HTTP error! Status: ${response.status}`);
    } else {
      helpers.logToOutput('POST: ProductHibernate: Success : ', response);
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: ProductHibernate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const stopProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}/stop`, {
      method: 'POST',
      body: JSON.stringify({})
    });

    if(response.status !== 200 ){
      throw new Error(`POST: ProductStop: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: ProductStop: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const presignProductLink = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/presign`, {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('presignProductLink response:', response);

    if (!response.ok) {
      throw new Error(`presignProductLink HTTP error! Status: ${response.status}`);
    }

    const responseJson = await response.json();
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('presignProductLink Error', error.message); // Setting the error state if there's an error
  }
}

export const getProductPresignedLink = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/presign`, {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('presignProductLink response:', response);

    if (response.status !== 200) {
      throw new Error(`presignProductLink HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();

      helpers.logToOutput('presignProductLink ResponseJson', responseJson);
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }

  } catch (error: any) {
    helpers.logToOutput('presignProductLink Error', error.message); // Setting the error state if there's an error

    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
  
}

export const getProductEncryptedCredentials = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    // Generate RSA key pair
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 2048, // 2048-bit key
        publicExponent: new Uint8Array([1, 0, 1]), // 65537
        hash: 'SHA-256',
      },
      true, // Whether the key is extractable (i.e., can be used in exportKey)
      ['encrypt', 'decrypt']
    );

    // Export the public key to send it to the API
    const publicKey = await window.crypto.subtle.exportKey(
      'spki', 
      keyPair.publicKey
    );

    // Convert publicKey to a format that can be sent in a JSON object (Base64)
    const publicKeyBase64 = btoa(String.fromCharCode(...Array.from(new Uint8Array(publicKey))));


    // Include the public key in the body of the POST request
    const requestBody = {
      ...query.body,
      publicKey: publicKeyBase64
    };

    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    helpers.logToOutput('presignProductLink response:', response);

    if (response.status !== 200) {
      throw new Error(`presignProductLink HTTP error! Status: ${response.status}`);
    } else {
      const responseJson = await response.json();

      // Decrypt the password using the private key
      const encryptedPassword = Uint8Array.from(atob(responseJson.encryptedPassword), c => c.charCodeAt(0));

      const decryptedPassword = await window.crypto.subtle.decrypt(
        {
          name: 'RSA-OAEP',
        },
        keyPair.privateKey,
        encryptedPassword
      );

      // Convert decryptedPassword from ArrayBuffer to string
      const decoder = new TextDecoder();
      const password = decoder.decode(decryptedPassword);

      helpers.logToOutput('presignProductLink decrypted password:', password);

      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: { password }
      }
    }

  } catch (error: any) {
    helpers.logToOutput('presignProductLink Error', error.message); // Setting the error state if there's an error

    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error.message
    }
  }
}

export const getProductCredentials = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    // Generate RSA key pair
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/password`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (response.status !== 200) {
      throw new Error(`getProductCredentials HTTP error! Status: ${response.status}`);
    } else {
      const responseJson = await response.json();
      helpers.logToOutput('getProductCredentials  password:', responseJson.parameter_value);
      const responseObj = JSON.parse(responseJson.parameter_value);
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: { responseObj }
      }
    }

  } catch (error: any) {
    helpers.logToOutput('getProductCredentials Error', error.message); // Setting the error state if there's an error

    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error.message
    }
  }
}

export const getMetadata = async(query: any): Promise<types.IApiHandledResponse> => {
  try {

    // Get screen sizes
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/data/meta/${query.body.metatype}/${query.body.metasubtype}/${query.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (response.status !== 200) {
      throw new Error(`getMetadata HTTP error! Status: ${response.status}`);
    } else {
      const responseJson = await response.json();
      helpers.logToOutput('getMetadata  responseJson]:', responseJson);
      const responseObj = responseJson.parameter_value;
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: { responseObj }
      }
    }

  } catch (error: any) {
    helpers.logToOutput('getMetadata Error', error.message); // Setting the error state if there's an error

    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error.message
    }
  }
}

export const getProductMeta = async(query: any): Promise<types.IApiHandledResponse> => {
  try {

    // Get screen sizes
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/meta/${query.body.key}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (response.status !== 200) {
      throw new Error(`getProductMeta HTTP error! Status: ${response.status}`);
    } else {
      const responseJson = await response.json();
      helpers.logToOutput('getProductMeta  responseJson]:', responseJson);
      const responseObj = responseJson.parameter_value;
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: { responseObj }
      }
    }

  } catch (error: any) {
    helpers.logToOutput('getProductMeta Error', error.message); // Setting the error state if there's an error

    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error.message
    }
  }
}

export const putProductMeta = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/meta`, {
      method: 'PUT',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('putProductMeta response:', response);

    if (!response.ok) {
      throw new Error(`putProductMeta HTTP error! Status: ${response.status}`);
    }

    const responseJson = await response.json();
    return response

  } catch (error: any) {
    helpers.logToOutput('putProductMeta Error', error.message); // Setting the error state if there's an error
    
  }
  
}

export const putProductPaste = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/copypaste`, {
      method: 'PUT',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('putProductPaste response:', response);

    if (!response.ok) {
      helpers.logToOutput(`putProductPaste HTTP error! Status: ${response.status}`);
    }

    const responseJson = await response.json();
    helpers.logToOutput('putProductPaste response:', response);
    helpers.logToOutput('putProductPaste responseJson:', responseJson);
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('putProductPaste Error', error.message); // Setting the error state if there's an error
  }
}

export const getShareProductUser = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/users`, {
      method: 'GET'
      //body: JSON.stringify(query.body)
    });

    helpers.logToOutput('getShareProductUser response:', response);

    if (!response.ok) {
      throw new Error(`getShareProductUser HTTP error! Status: ${response.status}`);
    }

    const responseJson = await response.json();
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('getShareProductUser Error', error.message); // Setting the error state if there's an error
  }
  
}

export const putShareProductUser = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/share`, {
      method: 'PUT',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('putShareProductUser response:', response);

    //if (!response.ok) {
    //  throw new Error(`putShareProductUser HTTP error! Status: ${response.status}`);
    //}

    const responseJson = await response.json();
    helpers.logToOutput('putShareProductUser response:', response);
    helpers.logToOutput('putShareProductUser responseJson:', responseJson);
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('putShareProductUser Error', error.message); // Setting the error state if there's an error
  }
  
}

export const putShareProductGroup = async(query: any) => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/sharegroup`, {
      method: 'PUT',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('putShareProductGroup response:', response);

    //if (!response.ok) {
    //  throw new Error(`putShareProductGroup HTTP error! Status: ${response.status}`);
    //}

    const responseJson = await response.json();
    helpers.logToOutput('putShareProductGroup response:', response);
    helpers.logToOutput('putShareProductGroup responseJson:', responseJson);
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('putShareProductGroup Error', error.message); // Setting the error state if there's an error
  }
}

export const delShareProductGroup = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/sharegroup`, {
      method: 'DELETE',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('delShareProduct response:', response);

    //if (!response.ok) {
    //  throw new Error(`delShareProduct HTTP error! Status: ${response.status}`);
    //}

    const responseJson = await response.json();
    helpers.logToOutput('delShareProduct return', responseJson)
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('delShareProduct Error', error.message); // Setting the error state if there's an error
    return error
  }
  
}

export const delShareProductUser = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/share`, {
      method: 'DELETE',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('delShareProductUser response:', response);

    //if (!response.ok) {
    //  throw new Error(`delShareProductUser HTTP error! Status: ${response.status}`);
    //}

    const responseJson = await response.json();
    helpers.logToOutput('delShareProductUser return', responseJson)
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('delShareProductUser Error', error.message); // Setting the error state if there's an error
    return error
  }
  
}

export const setUserAttributes = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
   * TO-DO:
   * - Check with API docs and update to work with UI
   * - can accept user's name, photo, etc
   */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    if(response.status !== 200 ){
      throw new Error(`POST: ProfileUpdate: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: ProfileUpdate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const getUserAttributes = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
   * TO-DO:
   * - Check with API docs and update to work with UI
   * - can accept user's name, photo, etc
   */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'GET',
    });

    if(response.status !== 200 ){
      throw new Error(`GET: UserAttributes: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET: UserAttributes: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const getProfilePhoto = async(_: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
   * TO-DO:
   * - Check with API docs and update to work with UI
   * - can accept user's name, photo, etc
   */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/users/profile/photo', {
      method: 'GET',
      
    });

    if(response.status !== 200 ){
      throw new Error(`GET: ProfilePhoto: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('PATCH: ProfileUpdate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const setProfilePhoto = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
   * TO-DO:
   * - Check with API docs and update to work with UI
   * - can accept user's name, photo, etc
   */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/users/profile/photo', {
      method: 'PUT',
      body: JSON.stringify(query.contentBinary),
      headers: {
        'Content-Type': query.contentType,
        'Content-Length': query.contentLength,
      },
    });

    if(response.status !== 200 ){
      throw new Error(`PUT: ProfilePhoto: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('PATCH: ProfileUpdate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const updateNotificationPreferences = async(query: any): Promise<types.IApiHandledResponse> => {
  
  /**
   * TO-DO:
   * - Needs Cognito
   * - can accept a notifications object of booleans
   * - eg {
   *  email: true,
   *  productUpdates: false,
   * -    }
   */
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/notification', {
      method: 'PATCH',
      body: JSON.stringify({
        changes: query.body
      })
    });

    if(response.status !== 200 ){
      throw new Error(`PATCH: NotificationsUpdate: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('PATCH: NotificationsUpdate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  createGroup  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: GROUP: POST: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/groups', {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('API: GROUP: POST: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`POST: User: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: GROUP: POST[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: Group: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  getGroups  = async(_: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/groups', {
      method: 'GET',
    });

    helpers.logToOutput('API: GROUPS: GET: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`GET: GROUP List: HTTP error! Status: ${response.status}`);
    } else {
      const responseJson = await response.json();
      helpers.logToOutput('API: USERS: GET[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET: GROUP List: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  editGroup  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: GROUP: EDIT: BODY: ', query)

    const updatedData = {
      ...query.body,
      users: query.body.users.map((user: any) => user.value),
    };
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/groups/group/${query.body.id}`, {
      method: 'PUT',
      body: JSON.stringify(updatedData)
    });

    helpers.logToOutput('API: Group: EDIT: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`EDIT: Group: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: Group: EDIT[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('EDIT: Group: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  delGroup  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    helpers.logToOutput('API: GROUP: DELETE: BODY: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/groups/group/${query.body.id}`, {
      method: 'DELETE',
    });

    helpers.logToOutput('API: GROUP: DELETE: ', response)
  
    if(response.status !== 200 ){
      helpers.logToOutput('DELETE: GROUP: HTTP error: ', response);
      throw new Error(`DELETE: GROUP: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: GROUP: DELETE[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('DELETE: GROUP: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  createUser  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: USERS: POST: BODY: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/users', {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('API: USERS: POST: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`POST: User: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USERS: POST[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: User: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  editUser  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: USERS: EDIT: BODY: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('API: USERS: EDIT: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`EDIT: User: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USERS: EDIT[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('EDIT: User: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  deleteUser  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: USERS: DELETE: BODY: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'DELETE',
    });

    helpers.logToOutput('API: USERS: DELETE: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`DELETE: User: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USERS: DELETE[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('DELETE: User: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  getUser  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: USERS: DELETE: BODY: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'GET',
    });

    helpers.logToOutput('API: USER: GET: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`GET: User: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USER: GET[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET: User: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  getUsers  = async(_: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/users', {
      method: 'GET',
    });

    helpers.logToOutput('API: USERS: GET: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`GET: UserList: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USERS: GET[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson['items']
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET: UserList: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const setAdminControl = async(query: any): Promise<types.IApiHandledResponse> => {
  try {


    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/admin/products/${query.id}/control`, {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    if(response.status !== 200 ){
      throw new Error(`POST: setAdminControl: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: setAdminControl: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}
